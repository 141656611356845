var $ = require('jquery');


/******************************************
	Smooth anchor scrolling
******************************************/

$('a[href*="#"]').not('[href="#"]').not('[href="#0"]').on('click', function(event) {
	if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname ) {
		// Figure out element to scroll to
		var target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
		
		// Does a scroll target exist?
		if (target.length) {
			// Only prevent default if animation is actually gonna happen
			event.preventDefault();
			$('html, body').animate({
				scrollTop: target.offset().top - 60
			}, 1000, function() {
				// Callback after animation
				// Must change focus!
				var $target = $(target);
				$target.focus();
				if ($target.is(":focus")) { // Checking if the target was focused
					return false;
				} else {
					$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
					$target.focus(); // Set focus again
				};
			});
		}
	}
});



/******************************************
	Nav
******************************************/

//  Burger

$(function() {
	$('.header__burger, .header__nav a').on('click', function() {
		$('html').toggleClass('nav-open');
		$('.header__burger').toggleClass('header__burger--active');
		$('.header__nav').toggleClass('header__nav--open');
	}); 
});




/******************************************
	YouTube Consent
******************************************/

$(function() {
	enableYoutube();

	var $enableBtn = $('.youtube-video__consent__btn');
	
	$enableBtn.on('click', function() {
		localStorage.setItem('youtubeConsent', 'true');
		enableYoutube();
	});
});


function enableYoutube() {
	var youtubeConsent = localStorage.getItem('youtubeConsent')

	if (youtubeConsent) {
		$('.youtube-video').each(function() {
			var $iframe = $('iframe', this)
			var iframeSrc = $iframe.data('src');
			$iframe.attr('src', iframeSrc);
			$('.youtube-video__consent').remove();
		});
	}
}